import type { MediaItem } from '../../../types/nrk/mediaitem';
import { getLogger } from '../logging/logger';
import DetailedErrorCode = cast.framework.events.DetailedErrorCode;
import ErrorData = cast.framework.messages.ErrorData;

export interface ErrorDetails {
  origin: string;
  error?: object | Error;
  errorData?: ErrorData;
  nonPlayable?: MediaItem['nonPlayable'];
}

const logger = getLogger('ErrorObserver');

export default class ErrorObserver {
  private castReceiverContext: cast.framework.CastReceiverContext;
  private playerManager: cast.framework.PlayerManager;
  private listeners: Array<(errorDetais: ErrorDetails) => unknown> = [];
  metadata?: MediaItem;

  constructor(castReceiverContext: cast.framework.CastReceiverContext) {
    this.castReceiverContext = castReceiverContext;
    this.playerManager = this.castReceiverContext.getPlayerManager();

    this.castReceiverContext.addEventListener(
      cast.framework.system.EventType.ERROR,
      this.onCastReceiverContextError,
    );
    this.playerManager.addEventListener(cast.framework.events.EventType.ERROR, this.onPlayerManagerError);
  }

  listen(listener: (errorDetails: ErrorDetails) => unknown) {
    this.listeners.push(listener);
  }

  unlisten(listener: (errorDetails: ErrorDetails) => unknown) {
    this.listeners = this.listeners.filter((l) => l !== listener);
  }

  private onCastReceiverContextError = (event: cast.framework.system.Event) => {
    logger.error(event);
    this.setError('CAST_RECIEVER', event.data);
  };

  private onPlayerManagerError = (event: cast.framework.events.ErrorEvent) => {
    const { detailedErrorCode, error } = event;

    const origin = (Object.keys(DetailedErrorCode) as Array<keyof typeof DetailedErrorCode>).find(
      (k) => DetailedErrorCode[k] === detailedErrorCode,
    );
    let errorInstance: Error | undefined;
    let errorData: ErrorData | undefined;
    if (error instanceof Error) {
      errorInstance = error;
    } else if (error && typeof error === 'object') {
      errorData = error as ErrorData;
    }

    this.setError(origin, errorInstance, errorData);
  };

  private setError(origin = 'UNKNOWN', error?: Error, errorData?: ErrorData) {
    const nonPlayable = this.metadata && this.metadata.nonPlayable;
    const errorDetails = {
      origin,
      error,
      errorData,
      nonPlayable,
    };

    this.triggerListeners(errorDetails);
  }

  private triggerListeners(errorDetails: ErrorDetails) {
    this.listeners.forEach((listener) => listener(errorDetails));
  }
}
