import type { MediaItem } from '../../../types/nrk/mediaitem';
import type { AppConfig } from '../appConfig';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { getChromecastModel, getMaxVideoLevel } from '../getChromecastModel';

interface CustomDimensions {
  [name: string]: string;
  applicationVersion: string;
  applicationName: string;
  env: string;
  userAgent: string;
  hostname: string;
}

export interface UserInfo {
  accessToken?: string;
  userId?: string;
  contentGroup?: string;
  ageRestriction?: string;
}

export interface ApplicationInsightsProperties {
  [key: string]: string | undefined;
}

export interface ApplicationInsightsMeasurements {
  [key: string]: number | undefined;
}

export class AppInsightsTracker {
  private appInsights: ApplicationInsights;
  private customDimensions: CustomDimensions;
  private trackedExceptions: { [message: string]: boolean } = {};
  private trackedErrorEvents: { [message: string]: boolean } = {};
  private trackedSenders: { [senderId: string]: boolean } = {};

  constructor(config: AppConfig) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: config.instrumentationKey,
        disableExceptionTracking: true,
        disableAjaxTracking: true,
        maxBatchSizeInBytes: 100,
        maxBatchInterval: 1000,
        enableSessionStorageBuffer: false,
        isStorageUseDisabled: true,
        isRetryDisabled: true,
        samplingPercentage: 10,
      },
    });
    this.appInsights.loadAppInsights();

    this.customDimensions = {
      applicationVersion: config.version,
      applicationName: 'nrk-cast-receiver',
      env: config.env,
      userAgent: window.navigator.userAgent,
      hostname: window.location.hostname,
    };

    this.appInsights.addTelemetryInitializer((envelope) => {
      const telemetryItem = envelope.data;
      if (telemetryItem === undefined) {
        return;
      }

      telemetryItem.properties = {
        ...this.customDimensions,
        ...telemetryItem.properties,
      };
    });
  }

  trackPageView() {
    this.appInsights.trackPageView({
      name: document.title,
      uri: window.location.href,
      properties: this.customDimensions,
    });
  }

  trackSender(sender?: cast.framework.system.Sender) {
    if (!sender) {
      return;
    }

    const senderId = getUniqueSenderId(sender.id);
    this.customDimensions.senderId = senderId;
    this.customDimensions.senderUserAgent = sender.userAgent;

    // Track sender once
    if (this.trackedSenders[senderId]) {
      return;
    }
    this.trackedSenders[senderId] = true;

    this.trackEvent('connected');
  }

  trackEvent(
    name: string,
    properties?: ApplicationInsightsProperties,
    measurements?: Record<string, number>,
  ) {
    const props = filterEmptyProps({
      ...this.customDimensions,
      ...properties,
    });
    this.appInsights.trackEvent({ name, properties: props, measurements });
  }

  setRecieverContext(castReceiverContext: cast.framework.CastReceiverContext) {
    const capabilities = castReceiverContext.getDeviceCapabilities();
    this.customDimensions.capabilities = JSON.stringify(capabilities);
    const applicationData = castReceiverContext.getApplicationData();
    if (!applicationData) {
      return;
    }
    const model = getChromecastModel();
    const videoLevel = getMaxVideoLevel();
    const app = {
      launchingSenderId: applicationData.launchingSenderId,
      launchedFrom: `${applicationData.launchedFrom}`,
      sessionId: `${applicationData.sessionId}`,
      appId: applicationData.id,
      model,
      videoLevel,
    };
    this.customDimensions.app = JSON.stringify(app);
  }

  setMediaItem(mediaItem?: MediaItem) {
    if (!mediaItem) {
      delete this.customDimensions.media;
      return;
    }
    const values = {
      id: mediaItem.id,
      title: mediaItem.title,
      mediaType: mediaItem.mediaType,
      duration: mediaItem.duration,
      medium: mediaItem.medium,
      nonPlayable: mediaItem.nonPlayable || {},
    };
    this.customDimensions.media = JSON.stringify(values);
  }

  setUserInfo(userInfo?: UserInfo) {
    if (!userInfo) {
      delete this.customDimensions.userInfo;
      return;
    }
    const values = {
      userId: userInfo.userId,
      contentGroup: userInfo.contentGroup,
      ageRestriction: userInfo.ageRestriction,
      accessToken: userInfo.accessToken,
    };
    this.customDimensions.userInfo = JSON.stringify(values);
  }

  setLoadRequest(loadRequestData: cast.framework.messages.LoadRequestData) {
    const media = loadRequestData.media;
    const hasInviteCode = !!(loadRequestData.customData && loadRequestData.customData.inviteCode);
    const values = {
      contentId: media.contentId,
      contentType: media.contentType,
      contentUrl: media.contentUrl,
      entity: media.entity,
      currentTime: loadRequestData.currentTime,
      hasInviteCode,
    };
    this.customDimensions.request = JSON.stringify(values);
  }

  trackException(error: Error, fatal = false, properties: ApplicationInsightsProperties = {}) {
    const exceptionKey = [error.message, error.stack, properties.reason, properties.origin].join('|');

    if (this.trackedExceptions[exceptionKey]) {
      return;
    }

    this.trackedExceptions[exceptionKey] = true;

    const props = filterEmptyProps({
      ...this.customDimensions,
      ...properties,
    });
    this.appInsights.trackException({
      error,
      properties: props,
      severityLevel: fatal ? SeverityLevel.Error : SeverityLevel.Warning,
    });
  }

  trackErrorEvent(errorEvent: ErrorEvent) {
    const errorKey = [errorEvent.message, errorEvent.filename, errorEvent.lineno, errorEvent.colno].join('|');

    if (this.trackedErrorEvents[errorKey]) {
      return;
    }

    this.trackedErrorEvents[errorKey] = true;

    this.appInsights._onerror({
      message: errorEvent.message,
      url: errorEvent.filename,
      lineNumber: errorEvent.lineno,
      columnNumber: errorEvent.colno,
      error: errorEvent.error,
    });
  }
}

/* eslint-disable */
export class NullAppInsightsTracker implements InterfaceOf<AppInsightsTracker> {
  setLoadRequest(loadRequestData: cast.framework.messages.LoadRequestData) {}
  setMediaItem(mediaItem: MediaItem) {}
  setUserInfo(userInfo?: UserInfo) {}
  setRecieverContext(castReceiverContext: cast.framework.CastReceiverContext) {}
  trackEvent(name: string, properties?: ApplicationInsightsProperties) {}
  trackException(error: Error, fatal?: boolean) {}
  trackPageView() {}
  trackSender(sender?: cast.framework.system.Sender) {}
  trackErrorEvent(errorEvent: ErrorEvent) {}
}
/* eslint-enable */

function filterEmptyProps<T>(obj?: { [key: string]: T | undefined }): { [key: string]: T } {
  if (!obj) {
    return {};
  }
  return Object.keys(obj).reduce(
    (o, k) => {
      const val = obj[k];
      if (typeof val !== 'undefined') {
        o[k] = val;
      }
      return o;
    },
    {} as { [key: string]: T },
  );
}

// Example: 531bf25a-1ff9-0c04-c37a-87618ea49862.18410:com.google.android.gms-12524
function getUniqueSenderId(id: string) {
  return id && id.split('.')[0];
}
