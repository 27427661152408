import type { MediaItem } from '../../../types/nrk/mediaitem';
import { IManifestsItem, IPlaybackMetadata } from './contracts/IPlaybackMetadata';
import { IPlaybackManifest } from './contracts/IPlaybackManifest';
import iso8601 from 'iso8601-duration';

export function mapPlaybackToMediaItem(
  metadata: IPlaybackMetadata,
  manifest?: IPlaybackManifest,
  manifestReference?: IManifestsItem,
  manifestSubtitles?: IManifestsItem[],
  loadRequestContentId?: string,
): MediaItem {
  const availability = metadata.availability;
  const live = availability && availability.live;
  const isLive = !!live;
  const liveType = live && live.type;
  const isChannel = liveType === 'channel';
  const links = metadata._links;
  const nextLink = links && links.next;
  const nextHref = nextLink && nextLink.href;
  const nextEpisodeId = (nextHref && nextHref.split('/').pop()) || null;
  const startNextEpisode = isLive ? 10 : null;
  const progress = links.progress;
  const progressLink = progress && progress.href;
  const id = metadata.id;
  const preplay = metadata.preplay;
  const titles = preplay && preplay.titles;
  const title = titles && titles.title;
  const subtitle = isLive ? 'Direkte' : (titles.subtitle ?? '');
  const description = preplay.description ?? '';
  const mediaType = metadata.sourceMedium === 'video' ? 'Video' : 'Audio';
  const poster = preplay && preplay.poster;
  const podcastImage = metadata._embedded.podcast && metadata._embedded.podcast.imageUrl;
  const posterImages = poster.images.length
    ? poster.images
    : podcastImage
      ? [{ url: podcastImage, pixelWidth: 0 }]
      : [];
  const images = posterImages.map((image) => ({
    imageUrl: image.url,
    pixelWidth: image.pixelWidth,
  }));
  const playable = manifest && manifest.playable;
  const playableAssets = (playable && playable.assets) || [];
  const mediaAssets = playableAssets.map((asset) => ({
    url: asset.url,
    mimeType: asset.mimeType,
  }));
  const duration = metadata.duration ? iso8601.toSeconds(iso8601.parse(metadata.duration)) : null;
  const subtitlesDefaultOn = true;
  const statistics = manifest && manifest.statistics;
  const scoresStatistics = statistics && statistics.scores;
  const googleAnalyticsStatistics = statistics && statistics.ga;
  const snowplowStatistics = statistics && statistics.snowplow;
  const manifestName = manifestReference && manifestReference.name;
  const currentManifest = findCurrentManifest(manifestSubtitles, id);
  const hasAllSpeechSubtitles = manifestSubtitles && manifestSubtitles.length ? true : false;
  const subtitles = playable && playable.subtitles;
  let nonPlayable: MediaItem['nonPlayable'] | undefined;
  if (metadata.nonPlayable) {
    nonPlayable = {
      type: metadata.nonPlayable.messageType,
      reason: metadata.nonPlayable.reason,
      message: metadata.nonPlayable.endUserMessage,
      messageSupplement: metadata.nonPlayable.endUserMessageSupplement,
    };
  }

  const mediaItem: MediaItem = {
    loadRequestContentId,
    id,
    title,
    subtitle,
    description,
    mediaType,
    images,
    mediaAssets,
    isLive,
    isChannel,
    duration,
    subtitlesDefaultOn,
    subtitles,
    startNextEpisode,
    nextEpisodeId,
    scoresStatistics,
    googleAnalyticsStatistics,
    snowplowStatistics,
    currentManifest,
    hasAllSpeechSubtitles,
    ...(progressLink ? { progressLink } : {}),
    ...(manifestName ? { manifestName } : {}),
  };
  if (nonPlayable) {
    mediaItem.nonPlayable = nonPlayable;
  }
  return mediaItem;
}

function findCurrentManifest(manifestSubtitles: IManifestsItem[] | undefined, contentId: string) {
  if (manifestSubtitles) {
    const found = manifestSubtitles.find((m) => m.href?.endsWith(contentId));
    return found?.name || 'default';
  }
  return 'default';
}
