import document from 'global/document';
import window from 'global/window';
import hyperHTML, { BoundTemplateFunction } from 'hyperhtml';
import { getHeadliners } from '../psapi/getHeadliners';
import preloadImage from '../http/preloadImage';
import { Headliners } from '../psapi/contracts/Headliners';

const { bind: hyper, wire } = hyperHTML;

export enum SlideshowState {
  STARTING,
  STARTED,
  STOPPING,
  STOPPED,
}

export default class Slideshow {
  private slideshowContainerElem: HTMLElement;
  private cycleTimer?: number;
  private startDelayTimer?: number;
  private startPromise?: Promise<void>;
  private dataURL: string;
  private slideTime: number;
  private state: SlideshowState;
  private render: BoundTemplateFunction<HTMLElement>;
  private startDelayRejectFunc?: (reason?: unknown) => void;
  private overriddenGetHeadliners: ((url: string) => Promise<Headliners>) | undefined;
  private overriddenPreloadImage: ((url: string) => Promise<void>) | undefined;

  constructor(
    options: { dataURL: string; slideTime: number },
    override?: {
      overriddenGetHeadliners?: (url: string) => Promise<Headliners>;
      preloadImage?: (url: string) => Promise<void>;
    },
  ) {
    this.state = SlideshowState.STOPPED;
    this.dataURL = options.dataURL;
    this.slideTime = options.slideTime * 1000;

    this.overriddenGetHeadliners = override && override.overriddenGetHeadliners;
    this.overriddenPreloadImage = override && override.preloadImage;

    const slideshowContainerElem = document.querySelector<HTMLElement>('.slideshow');
    if (slideshowContainerElem === null) {
      throw new Error('No .slideshow container element');
    }
    this.slideshowContainerElem = slideshowContainerElem;
    this.render = hyper(this.slideshowContainerElem);
  }

  update(programData: Slide[], currentSlide = 0, lazyImgSrc = false) {
    const createSlide = (item: Slide, index: number) => {
      const slideClassList = currentSlide === index ? 'slide slide--visible' : 'slide';
      let imgSrc: string | undefined = item.imageUrl;

      // Just-in-time caching of images by adding src to the image that is next up.
      // (Caching of the image on the first slide is handled elsewhere.)
      if (lazyImgSrc && index > currentSlide + 1) {
        imgSrc = undefined;
      }

      return wire(item)`
        <li class="${slideClassList}">
          <img src="${imgSrc}" class="slide__image" />
        </li>`;
    };

    this.render`
      <div class="slideshow__logo"></div>
      <ul>
        ${programData.map(createSlide)}
      </ul>
    `;
  }

  cycleSlides(programData: Slide[], slideTime = 20000, currentSlide = 0, firstCycle = true) {
    this.update(programData, currentSlide, firstCycle);

    // logger.log(currentSlide, programData.length, (currentSlide === programData.length - 1));
    if (firstCycle && currentSlide === programData.length - 1) {
      firstCycle = false;
    }

    currentSlide = (currentSlide + 1) % programData.length;

    this.cycleTimer = window.setTimeout(
      () => this.cycleSlides(programData, slideTime, currentSlide, firstCycle),
      slideTime,
    );
  }

  start(medium = 'tv', startDelayTime?: number): Promise<void> {
    if (this.state === SlideshowState.STARTED) {
      return Promise.resolve();
    } else if (this.state === SlideshowState.STARTING && this.startPromise !== undefined) {
      return this.startPromise;
    }

    this.state = SlideshowState.STARTING;

    this.startPromise = new Promise((resolve, reject) => {
      const runSlideshow = async () => {
        const headliners: Headliners = await (this.overriddenGetHeadliners || getHeadliners)(
          this.dataURL + medium,
        );
        const slides = extractSlidesFromHeadliners(headliners);

        if (this.state !== SlideshowState.STARTING) {
          reject();
          return;
        }

        // Preload first image to avoid fadein on halfway loaded image.
        // (Caching of the other images is handled elsewhere.)
        const firstImageUrl = slides[0] && slides[0].imageUrl;
        await (this.overriddenPreloadImage || preloadImage)(firstImageUrl);

        if (this.state !== SlideshowState.STARTING) {
          reject();
          return;
        }

        this.cycleSlides(slides, this.slideTime);
        this.slideshowContainerElem.classList.add('slideshow--active');

        this.state = SlideshowState.STARTED;

        resolve();
      };

      if (startDelayTime) {
        this.startDelayTimer = window.setTimeout(() => runSlideshow(), startDelayTime);
        this.startDelayRejectFunc = reject;
      } else {
        runSlideshow();
      }
    });

    return this.startPromise;
  }

  stop(): Promise<void> {
    if (this.startDelayTimer) {
      clearTimeout(this.startDelayTimer);

      try {
        this.startDelayRejectFunc?.(new Error('Slideshow aborted.'));
      } catch (_e) {
        // Ignore error thrown by startDelayRejectFunc, carry on.
      }
    }
    if (this.cycleTimer) {
      clearTimeout(this.cycleTimer);
    }

    if (this.state === SlideshowState.STOPPED || this.state === SlideshowState.STOPPING) {
      return Promise.resolve();
    }

    const computedStyle = window.getComputedStyle(this.slideshowContainerElem);
    const opacity = computedStyle.opacity ? parseFloat(computedStyle.opacity) : 1;

    // Transition will only trigger if opacity is > 0
    if (opacity === 0) {
      this.state = SlideshowState.STOPPED;
      this.slideshowContainerElem.classList.remove('slideshow--active');
      this.render``;
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      this.state = SlideshowState.STOPPING;

      const handleTransitionEnd = () => {
        this.render``;
        this.state = SlideshowState.STOPPED;
        this.slideshowContainerElem.removeEventListener('transitionend', handleTransitionEnd);
        resolve();
      };

      this.slideshowContainerElem.addEventListener('transitionend', handleTransitionEnd);
      this.slideshowContainerElem.classList.remove('slideshow--active');
    });
  }
}

export interface Slide {
  title: string;
  description: string;
  imageUrl: string;
}

export function extractSlidesFromHeadliners(data: Headliners): Slide[] {
  return data.headliners
    .filter((h) => h.images.length)
    .map(({ title, subTitle: description, images = [] }) => {
      const imageUrl = images[images.length - 1].uri;
      return {
        title,
        description,
        imageUrl,
      };
    });
}
