import throttle from 'lodash-es/throttle';

export interface UserProgress {
  position: number;
  startPlaybackPosition: number;
}

type UserProgessListener = (progress: UserProgress) => void;

/*
 Emits an event each time the user progress should be updated according to the specification.
 */
export class UserProgressObserver {
  private listeners: UserProgessListener[] = [];
  private playerManager: cast.framework.PlayerManager;
  private intervalID: number | undefined;
  private onProgressChangedThrottled: () => void;
  private enabled = false;
  private previousPosition: number | undefined;
  private startPlaybackPosition: number | undefined;

  constructor(playerManager: cast.framework.PlayerManager) {
    this.playerManager = playerManager;
    this.playerManager.addEventListener(cast.framework.events.EventType.ALL, this.onPlayerManagerEvent);
    this.onProgressChangedThrottled = throttle(this.onProgressChanged, 1000);
  }

  listen(listener: UserProgessListener) {
    this.listeners.push(listener);
  }

  unlisten(listener: UserProgessListener) {
    this.listeners = this.listeners.filter((l) => l !== listener);
  }

  stop() {
    this.onProgressChangedThrottled();
    this.stopDetection();
    this.enabled = false;
    this.previousPosition = undefined;
    this.startPlaybackPosition = undefined;
  }

  start() {
    this.enabled = true;
    this.previousPosition = undefined;
    this.startPlaybackPosition = undefined;
  }

  private onPlayerManagerEvent = (e: cast.framework.events.Event) => {
    const { type } = e;

    switch (type) {
      case cast.framework.events.EventType.PLAYER_LOADING:
        break;

      case cast.framework.events.EventType.PLAYER_LOAD_COMPLETE:
        {
          const loadEvent = e as cast.framework.events.LoadEvent;
          const isLive =
            loadEvent.media && loadEvent.media.streamType === cast.framework.messages.StreamType.LIVE;

          if (!isLive && this.enabled) {
            this.startDetection();
          }
        }
        break;

      case cast.framework.events.EventType.MEDIA_FINISHED:
        this.stopDetection();
        break;

      case cast.framework.events.EventType.SEEKED:
        this.startPlaybackPosition = this.getFlooredCurrentTime();
        this.restartDetection();
        break;

      case cast.framework.events.EventType.REQUEST_SEEK:
      case cast.framework.events.EventType.SEEKING:
        this.onProgressChangedThrottled();
        delete this.startPlaybackPosition;
        break;

      case cast.framework.events.EventType.BUFFERING:
      case cast.framework.events.EventType.PAUSE:
      case cast.framework.events.EventType.PLAYING:
        this.onProgressChangedThrottled();
        break;

      default:
        break;
    }
  };

  private getFlooredCurrentTime() {
    return Math.floor(this.playerManager.getCurrentTimeSec());
  }

  private stopDetection() {
    if (this.intervalID) {
      window.clearInterval(this.intervalID);
    }
    delete this.intervalID;
  }

  private startDetection() {
    this.stopDetection();
    this.intervalID = window.setInterval(() => this.onProgressChangedThrottled(), 30000);
  }

  private restartDetection() {
    if (this.intervalID) {
      this.startDetection();
    }
  }

  private onProgressChanged() {
    if (!this.enabled) {
      return;
    }

    const position = this.getFlooredCurrentTime();
    const startPlaybackPosition =
      typeof this.startPlaybackPosition === 'undefined' ? position : this.startPlaybackPosition;
    const previousPosition = this.previousPosition;
    this.startPlaybackPosition = startPlaybackPosition;
    this.previousPosition = position;

    if (!hasProgressChangedSufficiently(position, previousPosition, startPlaybackPosition)) {
      return;
    }

    const progress = {
      position,
      startPlaybackPosition,
    };

    this.listeners.forEach((listener) => listener(progress));

    this.restartDetection();
  }
}

function hasProgressChangedSufficiently(
  position: number,
  previousPosition: number | undefined,
  startPlaybackPosition: number,
) {
  if (position < 10) {
    return false;
  }
  if (previousPosition && Math.abs(position - previousPosition) <= 1) {
    return false;
  }
  return position > startPlaybackPosition + 2;
}
