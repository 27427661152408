import { parseShareLink } from './share-link';
import { getSeries } from '../psapi/getSeries';
import {
  getFirstEpisodeInSeason,
  getHighlightedSequentialEpisode,
  getHighlightedStandardEpisode,
} from './series-to-episode';

/**
 * Assuming the URL is an NRK share link, attempt to parse it and figure out
 * the media it targets (media ID and start time).
 */
export async function getMediaFromShareLink(
  url: string,
): Promise<{ mediaId: string; startTime?: number } | undefined> {
  const shareLink = parseShareLink(url);

  if (shareLink.mediaId !== undefined) {
    return {
      mediaId: shareLink.mediaId,
      startTime: shareLink.startTime,
    };
  }

  if (shareLink.seriesId !== undefined) {
    const series = await getSeries(shareLink.seriesId);

    let mediaId: string | undefined;

    if (shareLink.seasonId !== undefined) {
      mediaId = await getFirstEpisodeInSeason(series, shareLink.seasonId);
    } else if (series.sequential) {
      mediaId = getHighlightedSequentialEpisode(series);
    } else if (series.standard) {
      mediaId = getHighlightedStandardEpisode(series);
    }

    if (mediaId !== undefined) {
      return { mediaId };
    }
  }

  return undefined;
}
