import type { MediaItem } from '../../../types/nrk/mediaitem';
import EventEmitter from 'eventemitter3';
import { getLogger } from '../logging/logger';

export enum CustomCommands {
  CANCEL_NEXT_EPISODE = 'CANCEL_NEXT_EPISODE',
  DEBUG_RECEIVER = 'DEBUG_RECEIVER',
  REMOTE_CONSOLE = 'REMOTE_CONSOLE',
  NO_SUBTITLES = 'NO_SUBTITLE',
  RECEIVER_NAME = 'RECEIVER_NAME',
  SET_METADATA = 'SET_METADATA',
  SUBS_OFF = 'SUBS_OFF',
  SUBS_ON = 'SUBS_ON',
  SUB_HIDDEN = 'SUB_HIDDEN',
  SUB_STATUS = 'SUB_STATUS',
  SUB_VISIBLE = 'SUB_VISIBLE',
  TOGGLE_HUD = 'TOGGLE_HUD',
  TOGGLE_SUB = 'TOGGLE_SUB',
  TRIGGER_ERROR = 'TRIGGER_ERROR',
  HIDE_SPINNER = 'HIDE_SPINNER',
  SHOW_SPINNER = 'SHOW_SPINNER',
  REQUEST_CODE_CHALLENGE = 'REQUEST_CODE_CHALLENGE',
  CODE_CHALLENGE = 'CODE_CHALLENGE',
  REDEEM_INVITE_CODE = 'REDEEM_INVITE_CODE',
  USER_AUTHENTICATED = 'USER_AUTHENTICATED',
  USER_REJECTED = 'USER_REJECTED',
}

export enum CustomNamespace {
  COMMAND = 'urn:x-cast:nrktv.chromecast.command',
  ERROR = 'urn:x-cast:nrktv.chromecast.error',
  DEBUG = 'urn:x-cast:nrktv.chromecast.debug',
}

const logger = getLogger('CustomMessageHandler');

export default class CustomMessageHandler {
  private emitter: EventEmitter;
  private castReceiverContext: cast.framework.CastReceiverContext;
  private enableRemoteDebug = true;

  constructor(castReceiverContext: cast.framework.CastReceiverContext) {
    this.castReceiverContext = castReceiverContext;
    this.emitter = new EventEmitter();

    this.castReceiverContext.addCustomMessageListener(CustomNamespace.ERROR, this.onError);
    this.castReceiverContext.addCustomMessageListener(CustomNamespace.COMMAND, this.onCommand);
    this.castReceiverContext.addCustomMessageListener(CustomNamespace.DEBUG, this.onDebug);
  }

  on(
    namespace: CustomNamespace,
    listener: (event: cast.framework.system.CustomMessageEvent, command?: CustomCommands) => void,
  ) {
    this.emitter.on(namespace, listener);
  }

  off(
    namespace: CustomNamespace,
    listener: (event: cast.framework.system.CustomMessageEvent, command?: CustomCommands) => void,
  ) {
    this.emitter.off(namespace, listener);
  }

  toggleRemoteDebug() {
    this.enableRemoteDebug = !this.enableRemoteDebug;
  }

  sendCommand(command: CustomCommands | string, blob: object = {}, senderId?: string) {
    const message = {
      ...blob,
      command,
    };
    this.castReceiverContext.sendCustomMessage(CustomNamespace.COMMAND, senderId, message);
  }

  sendError(error: string, nonPlayable: MediaItem['nonPlayable'], senderId?: string) {
    const message = { error, nonPlayable };
    this.castReceiverContext.sendCustomMessage(CustomNamespace.ERROR, senderId, message);
  }

  private onError = (event: cast.framework.system.CustomMessageEvent) => {
    this.emitter.emit(CustomNamespace.ERROR, event);
  };

  private onCommand = (event: cast.framework.system.CustomMessageEvent) => {
    const { data = {} } = event,
      { command } = data;

    this.emitter.emit(CustomNamespace.COMMAND, event, command);
  };

  private onDebug(event: cast.framework.system.CustomMessageEvent) {
    logger.log(event);
  }
}
