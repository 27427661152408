import { getLogger } from '../logging/logger';
import { MediaInformationCustomData } from './custom-data/media-information-custom-data';

export type TextTrackChangedListener = (event: {
  active?: cast.framework.messages.Track;
  target?: TargetTextTrack;
}) => void;

export interface TargetTextTrack {
  name?: string;
  on: boolean;
}

const logger = getLogger('TextTrackHandler');

export default class TextTrackHandler {
  private playerManager: cast.framework.PlayerManager;
  private textTracksManager: cast.framework.TextTracksManager;
  private listeners: TextTrackChangedListener[] = [];

  /**
   * The target values are what the user want. Values can be stored before the
   * first media is loaded and are kept between media, to be applied when the
   * (next) media is loaded. The applyTargetTextTrack() method processes the
   * values.
   */
  private target?: TargetTextTrack;

  constructor(playerManager: cast.framework.PlayerManager) {
    this.playerManager = playerManager;
    this.textTracksManager = playerManager.getTextTracksManager();

    // Default target.
    this.target = { on: true };

    playerManager.addEventListener(
      cast.framework.events.EventType.PLAYER_LOAD_COMPLETE,
      this.handlePlayerLoadComplete,
    );

    playerManager.setMessageInterceptor(
      cast.framework.messages.MessageType.EDIT_TRACKS_INFO,
      this.handleEditTracksRequest,
    );
  }

  listen(listener: TextTrackChangedListener) {
    this.listeners.push(listener);
  }

  unlisten(listener: TextTrackChangedListener) {
    const index = this.listeners.lastIndexOf(listener);
    if (index !== -1) {
      this.listeners.splice(index, 1);
    }
  }

  toggle(on?: boolean): void {
    if (on === undefined) {
      on = this.getActiveTrack() === undefined;
    }

    if (this.target === undefined) {
      this.target = { on };
    } else {
      this.target.on = on;
    }

    this.applyTargetTextTrack();
  }

  setActiveTrackByName(name: string): void {
    this.target = { name, on: true };
    this.applyTargetTextTrack();
  }

  /**
   * Change the active track, potentially to none. Check if the track actually
   * changed and update the target state and emit events accordingly.
   *
   * All track changes must eventually call this method.
   */
  private setActiveTrackById(id: number | undefined) {
    const activeTrackBefore = this.getActiveTrack();

    this.setActiveByIds(id !== undefined ? [id] : null);

    const activeTrackAfter = this.getActiveTrack();

    if (activeTrackAfter !== undefined) {
      this.target = { name: activeTrackAfter.name, on: true };
    }

    if (activeTrackAfter?.trackId !== activeTrackBefore?.trackId) {
      this.emitTextTrackChanged();
    }
  }

  getActiveTrack(): cast.framework.messages.Track | undefined {
    return this.getActiveTracks()[0];
  }

  hasTextTracks(): boolean {
    return this.getTracks().length > 0;
  }

  private applyTargetTextTrack(): void {
    // Turn off?
    if (!this.target?.on) {
      this.setActiveTrackById(undefined);
      return;
    }

    // Turn on.
    let track: cast.framework.messages.Track | undefined;
    if (this.target.name !== undefined) {
      track = this.getTrackByName(this.target.name);
    }
    // Fall back to default if the desired track isn't available.
    if (track === undefined) {
      track = this.getDefaultTrack();
    }

    this.setActiveTrackById(track?.trackId);
  }

  private handlePlayerLoadComplete = (): void => {
    this.textTracksManager.setTextTrackStyle(getTextTrackStyle());

    const mediaInformation = this.playerManager.getMediaInformation();
    const customData = (mediaInformation.customData ?? {}) as MediaInformationCustomData;

    // Prefer media provided text tracks. Fall back to alternative text
    // tracks, if any.
    if (
      !this.hasTextTracks() &&
      customData.altTextTracks !== undefined &&
      customData.altTextTracks.length > 0
    ) {
      logger.log('No text tracks provided in media. Using alternative text tracks from customData.');

      // Take audio tracks into account when updating track IDs. Track IDs
      // must be unique per MediaInformation.
      const trackIdBase = (mediaInformation.tracks?.length ?? 0) + 1;
      this.textTracksManager.addTracks(
        customData.altTextTracks.map((track, index) => {
          track.trackId = trackIdBase + index;
          return track;
        }),
      );
    }

    // XXX: HACK! If no track is marked as 'main', attempt to set the
    // "NRK default" as main.
    const tracks = this.getTracks();
    if (!tracks.some((track) => track.roles?.includes('main'))) {
      const track = tracks.find((track) => track.name === 'Norsk – kun ved annet språk');
      if (track !== undefined) {
        (track.roles ??= []).push('main');
      }
    }

    // Set active track.
    if (customData.activeTextTrack !== undefined) {
      logger.log('Setting active text track to:', customData.activeTextTrack);

      if (typeof customData.activeTextTrack === 'string') {
        this.setActiveTrackByName(customData.activeTextTrack);
      } else {
        this.toggle(customData.activeTextTrack);
      }
    } else {
      this.applyTargetTextTrack();
    }

    customData.altTextTracks = undefined;
  };

  /**
   * Intercept edit tracks info requests. If we didn't have the custom commands
   * which report subtitles state, then most of this would probably be
   * unnecessary, as we wouldn't have to pick up and report changes to the
   * active track(s). But now we kind of need to handle it all.
   */
  private handleEditTracksRequest = (
    requestData: cast.framework.messages.EditTracksInfoRequestData,
  ): cast.framework.messages.EditTracksInfoRequestData => {
    if (requestData.activeTrackIds !== undefined) {
      // Pick the first valid text track ID. If there are none, then the list
      // probably only contained non-text track IDs.
      const trackId = requestData.activeTrackIds.find((id) => this.getTrackById(id) !== undefined);
      if (trackId !== undefined) {
        this.setActiveTrackById(trackId);
      } else {
        this.toggle(false);
      }
    } else if (requestData.language !== undefined) {
      const tracks = this.getTracksByLanguage(requestData.language);

      if (tracks.length > 0) {
        sortTextTracks(tracks);
        this.setActiveTrackById(tracks[0].trackId);
      } else {
        const language = requestData.language.split(/[-_]/)[0].toLowerCase();
        const norwegianLanguages = ['no', 'nb', 'nn'];

        // If the language is a Norwegian language, then try similar
        // languages/codes (doesn't apply to Sami languages, they are too
        // dissimilar).
        if (norwegianLanguages.includes(language)) {
          let alternativeTrack: cast.framework.messages.Track | undefined;
          for (const code of norwegianLanguages) {
            const tracks = this.getTracksByLanguage(code);
            if (tracks.length > 0) {
              sortTextTracks(tracks);
              alternativeTrack = tracks[0];
              break;
            }
          }
          if (alternativeTrack !== undefined) {
            this.setActiveTrackById(alternativeTrack.trackId);
          } else {
            // XXX: Possible alternative: Show a message on screen saying no
            // subtitles for language x.
            this.toggle(false);
          }
        } else {
          // If the langauge code refers to the spoken language in a general
          // voice command to turn subtitles on (and the spoken language didn't
          // match above), then just turn on the default or the previously used
          // track. The user didn't directly specify which language they wanted
          // the track to have anyway.
          //
          // XXX: Possible alternative to toggle(false): Show a message on
          // screen saying no subtitles for language x.
          this.toggle(requestData.isSuggestedLanguage);
        }
      }
    }

    // Turn the request into a dud before handing it back to the cast
    // framework.
    requestData.activeTrackIds = undefined;
    requestData.language = undefined;
    return requestData;
  };

  /**
   * Returns the track marked as main, or whichever track regarded as most
   * desirable.
   */
  private getDefaultTrack(): cast.framework.messages.Track | undefined {
    const tracks = this.getTracks();
    sortTextTracks(tracks);
    return tracks[0];
  }

  private getTrackByName(name: string): cast.framework.messages.Track | undefined {
    return this.getTracks().find((track) => track.name === name);
  }

  private emitTextTrackChanged() {
    const active = this.getActiveTrack();

    this.listeners.forEach((listener) =>
      listener({
        active,
        target: this.target,
      }),
    );
  }

  // Wrapper methods, protecting against "Uncaught Error: Tracks info is not
  // available."

  private getTracks(): cast.framework.messages.Track[] {
    try {
      return this.textTracksManager.getTracks();
    } catch (e) {
      logger.debug('Failed to call getTracks()', `${e}`);
      return [];
    }
  }

  private getActiveTracks(): cast.framework.messages.Track[] {
    try {
      return this.textTracksManager.getActiveTracks();
    } catch (e) {
      logger.debug('Failed to call getActiveTracks()', `${e}`);
      return [];
    }
  }

  private getTrackById(id: number): cast.framework.messages.Track | undefined {
    try {
      return this.textTracksManager.getTrackById(id) ?? undefined;
    } catch (e) {
      logger.debug('Failed to call getTrackById()', `${e}`);
      return undefined;
    }
  }

  private getTracksByLanguage(language: string): cast.framework.messages.Track[] {
    try {
      return this.textTracksManager.getTracksByLanguage(language);
    } catch (e) {
      logger.debug('Failed to call getTracksByLanguage()', `${e}`);
      return [];
    }
  }

  /**
   * Don't use this directly. Use setActiveTrackById()!
   */
  private setActiveByIds(ids: number[] | null): void {
    try {
      this.textTracksManager.setActiveByIds(ids);
    } catch (e) {
      logger.debug('Failed to call setActiveByIds()', `${e}`);
    }
  }
}

function getTextTrackStyle(): cast.framework.messages.TextTrackStyle {
  const style = new cast.framework.messages.TextTrackStyle();
  style.backgroundColor = '#000000B0';
  style.fontScale = 0.8;
  style.fontFamily = 'Helvetica Neue,Helvetica,Arial,sans-serif';
  style.fontGenericFamily = cast.framework.messages.TextTrackFontGenericFamily.SANS_SERIF;
  return style;
}

/**
 * Sort a list of text tracks by priority. Main > Captions > Other.
 */
export function sortTextTracks(tracks: cast.framework.messages.Track[]): void {
  tracks.sort((a, b) => {
    const aIsMain = a.roles?.includes('main') ? -1 : 0;
    const bIsMain = b.roles?.includes('main') ? -1 : 0;
    if (aIsMain !== bIsMain) {
      return aIsMain - bIsMain;
    }

    const aIsCaptions =
      a.subtype === cast.framework.messages.TextTrackType.CAPTIONS || a.subtype === 'CAPTION' ? -1 : 0;
    const bIsCaptions =
      b.subtype === cast.framework.messages.TextTrackType.CAPTIONS || b.subtype === 'CAPTION' ? -1 : 0;

    if (aIsCaptions !== bIsCaptions) {
      return aIsCaptions - bIsCaptions;
    }

    return (a.name ?? a.language ?? `${a.trackId}`).localeCompare(b.name ?? b.language ?? `${b.trackId}`);
  });
}
