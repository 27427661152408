import PlaybackProgression from './PlaybackProgression';
import { AuthenticationHandler } from './AuthenticationHandler';
import { applyProgressionToMediaInformation } from './applyProgressionToMediaInformation';
import { applyUserInfoToMediaInformation } from './applyUserInfoToMediaInformation';
import { TimeManager } from './time-manager';

export class MediaInformationApplier {
  private playbackProgression: PlaybackProgression;
  private timeManager: TimeManager;
  private authenticationHandler: AuthenticationHandler;

  constructor(
    playbackProgression: PlaybackProgression,
    timeManager: TimeManager,
    authenticationHandler: AuthenticationHandler,
  ) {
    this.playbackProgression = playbackProgression;
    this.timeManager = timeManager;
    this.authenticationHandler = authenticationHandler;
  }

  applyTo(mediaInformation: cast.framework.messages.MediaInformation | undefined) {
    if (!mediaInformation) {
      return;
    }
    if (!mediaInformation.customData) {
      mediaInformation.customData = {};
    }
    applyProgressionToMediaInformation(mediaInformation, this.playbackProgression, this.timeManager);
    applyUserInfoToMediaInformation(mediaInformation, this.authenticationHandler);
  }
}
