interface TrackedError extends Pick<cast.framework.events.ErrorEvent, 'detailedErrorCode' | 'error'> {
  timestamp: Date;
}

interface TrackedBuffering {
  start: Date;
  startCurrentTime: number;
  end?: Date;
  endCurrentTime?: number;
}

interface PlayerState {
  currentTime: number;
}

export interface PlaybackStatsReport {
  suspend: number;
  waiting: number;
  stalled: number;
  buffering: {
    min: number;
    avg: number;
    max: number;
    count: number;
    sum: number;
  };
  endedReason: string | undefined;
  errors: Array<{
    code: number;
    message: string;
  }>;
  bitrates: number[];
  sessionDuration: number;
  durationSec: number;
  playingStartupTime: number;
  streamBandwidth?: number;
  downloadSpeed?: number;
}

export class PlaybackStats {
  private errors: TrackedError[] = [];
  private bufferings: TrackedBuffering[] = [];
  private bitrates: number[] = [];
  private downloads: [number, number][] = [];
  private currentBuffering: TrackedBuffering | undefined;
  private endedReason: cast.framework.events.EndedReason | undefined;
  private _hasFinished = false;
  private stalledCount = 0;
  private suspendCount = 0;
  private waitingCount = 0;
  private startTime: Date;
  private endTime: Date | undefined;
  private durationSec = 0;
  private firstPlayingTime: Date | undefined;
  private streamBandwidth: number | undefined;

  constructor() {
    this.startTime = new Date();
  }

  startBuffering(state: PlayerState) {
    this.currentBuffering = {
      start: new Date(),
      startCurrentTime: state.currentTime,
    };
  }

  endBuffering(state: PlayerState) {
    this.endCurrentBuffering(state);
  }

  playing(_state: PlayerState) {
    if (!this.firstPlayingTime) {
      this.firstPlayingTime = new Date();
    }
  }

  error(errorEvent: cast.framework.events.ErrorEvent) {
    this.errors.push({
      timestamp: new Date(),
      detailedErrorCode: errorEvent.detailedErrorCode,
      error: errorEvent.error,
    });
  }

  stalled() {
    this.stalledCount++;
  }

  suspend() {
    this.suspendCount++;
  }

  waiting() {
    this.waitingCount++;
  }

  bitrateChanged(event: cast.framework.events.BitrateChangedEvent) {
    this.bitrates.push(event.totalBitrate);
  }

  segmentDownloaded(event: cast.framework.events.SegmentDownloadedEvent) {
    const { size, downloadTime } = event;
    if (size && downloadTime) {
      this.downloads.push([size, downloadTime]);
    }
  }

  setDuration(durationSec: number) {
    this.durationSec = durationSec;
  }

  setStats(stats?: cast.framework.Stats) {
    const streamBandwidth = stats?.streamBandwidth;
    if (streamBandwidth) {
      this.streamBandwidth = streamBandwidth;
    }
  }

  finished(endedReason: cast.framework.events.EndedReason | undefined) {
    this.endCurrentBuffering();

    this.startBuffering = () => {};
    this.endBuffering = () => {};
    this.playing = () => {};
    this.error = () => {};
    this.stalled = () => {};
    this.suspend = () => {};
    this.waiting = () => {};
    this.bitrateChanged = () => {};
    this.finished = () => {};
    this.setDuration = () => {};
    this.segmentDownloaded = () => {};
    this.setStats = () => {};

    this.endTime = new Date();
    this.endedReason = endedReason;
    this._hasFinished = true;
  }

  get hasFinished() {
    return this._hasFinished;
  }

  get duration() {
    return this.durationSec;
  }

  getReport(): PlaybackStatsReport {
    const errors = this.errors.map((e) => ({
      code: e.detailedErrorCode || -1,
      message: getErrorMessage(e.error),
    }));
    const sessionDuration = this.endTime ? this.endTime.getTime() - this.startTime.getTime() : 0;
    const { durationSec, bitrates } = this;
    const playingStartupTime = this.firstPlayingTime
      ? this.firstPlayingTime.getTime() - this.startTime.getTime()
      : 0;
    const downloadSpeed = this.getDownloadSpeedInKilobytesPerSeconds();

    return {
      errors,
      bitrates,
      buffering: this.getBufferingDetails(),
      stalled: this.stalledCount,
      suspend: this.suspendCount,
      waiting: this.waitingCount,
      endedReason: this.endedReason,
      streamBandwidth: this.streamBandwidth,
      sessionDuration,
      durationSec,
      playingStartupTime,
      downloadSpeed,
    };
  }

  private getBufferingDetails() {
    const durations = this.bufferings
      .filter(
        (buffering: TrackedBuffering): buffering is Required<TrackedBuffering> => buffering.end !== undefined,
      )
      .map(({ start, end }) => end.getTime() - start.getTime())
      .filter((duration) => duration > 0);

    const count = durations.length;
    let min = 0;
    let max = 0;
    let sum = 0;
    let avg = 0;
    if (count) {
      min = Math.min(...durations);
      max = Math.max(...durations);
      sum = durations.reduce((prev, dur) => prev + dur, 0);
      avg = sum / count;
    }

    return {
      count,
      min,
      max,
      sum,
      avg,
    };
  }

  private endCurrentBuffering(state?: PlayerState) {
    if (!this.currentBuffering) {
      return;
    }

    const currentBuffering = this.currentBuffering;
    delete this.currentBuffering;
    currentBuffering.end = new Date();
    currentBuffering.endCurrentTime = state && state.currentTime;
    this.bufferings.push(currentBuffering);
  }

  private getDownloadSpeedInKilobytesPerSeconds() {
    const sum = this.downloads.reduce(
      (aggregate, [bytes, downloadTimeMs]) => {
        return [aggregate[0] + bytes, aggregate[1] + downloadTimeMs];
      },
      [0, 0],
    );

    const [bytes, downloadTimeMs] = sum;

    if (!bytes || !downloadTimeMs) {
      return 0;
    }

    return Math.floor(bytes / 1024) / Math.floor(downloadTimeMs / 1000);
  }
}

function getErrorMessage(error: object | Error | undefined) {
  if (!error) {
    return `${error}`;
  }
  if (error instanceof Error) {
    return error.message;
  }
  try {
    return JSON.stringify(error);
  } catch (_e) {
    return error.toString();
  }
}
