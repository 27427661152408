import { AuthenticationHandler } from './AuthenticationHandler';

export function applyUserInfoToMediaInformation(
  mediaInformation: cast.framework.messages.MediaInformation | undefined,
  authenticationHandler: AuthenticationHandler,
) {
  if (!mediaInformation) {
    return false;
  }

  mediaInformation.customData = mediaInformation.customData || {};
  const customData = mediaInformation.customData;
  const { userId, userName } = authenticationHandler;
  if (userId) {
    customData.user = { userId, userName };
  } else {
    delete customData.user;
  }

  return true;
}
