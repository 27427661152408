import { getLogger } from '../logging/logger';
import HttpRequestError from './HttpRequestError';

const logger = getLogger('httpGetJson');

export async function httpGetJson<T>(
  url: string,
  headers: Record<string, string> | Headers = {},
): Promise<T> {
  logger.log(url, headers);

  const options = { headers };
  let response: Response;

  try {
    response = await fetch(url, options);
  } catch (err) {
    throw new HttpRequestError('Request failed', { url, error: err as Error });
  }

  if (!response) {
    throw new HttpRequestError('No response from server', { url });
  }

  if (response.status !== 200) {
    throw new HttpRequestError(`Unexpected status code ${response.status}`, { url });
  }

  try {
    return await response.json();
  } catch (err) {
    throw new HttpRequestError('Could not parse json response', { url, error: err as Error });
  }
}
