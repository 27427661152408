import document from 'global/document';
import { bind as hyper, BoundTemplateFunction } from 'hyperhtml';
import merge from 'lodash-es/merge';

export interface CountdownProps {
  subtitle?: string;
  imageUrl?: string;
  title?: string;
  remaining?: number;
}

function getDefaultProps(): CountdownProps {
  return {
    subtitle: '',
    imageUrl: '',
    title: '',
    remaining: 0,
  };
}

export default class CountdownOverlay {
  private props: CountdownProps;
  private containerElem: HTMLElement;
  private render: BoundTemplateFunction<HTMLElement>;

  constructor() {
    const containerElem = document.querySelector<HTMLElement>('.countdown');
    if (containerElem === null) {
      throw new Error('.countdown element missing');
    }

    this.props = getDefaultProps();
    this.containerElem = containerElem;

    this.render = hyper(this.containerElem);
  }

  update(newProps?: CountdownProps) {
    this.props = merge(this.props, newProps);

    const visible =
      typeof this.props.remaining === 'number' && this.props.remaining >= 0 && !!this.props.title;

    this.containerElem.classList.toggle('countdown--active', visible);

    if (!visible) {
      return;
    }

    const remainingText = this.props.remaining === 1 ? 'sekund' : 'sekunder';

    this.render`
      <div class="countdown__program">
        <p class="countdown__counter">Om <span class="countdown__remaining">${this.props.remaining} ${remainingText}</span> starter</p>
        <div class="countdown__media">
          <div class="countdown__figure">
            <img class="countdown__image" src="${this.props.imageUrl}">
          </div>
          <div class="countdown__metadata">
            <h1 class="countdown__metadata__title">${this.props.title}</h1>
            <h2 class="countdown__metadata__subtitle">${this.props.subtitle}</h2>
          </div>
        </div>
      </div>
    `;
  }

  reset() {
    this.props = getDefaultProps();
    this.update();

    const handleTransitionEnd = () => {
      this.containerElem.removeEventListener('transitionend', handleTransitionEnd);
      this.render``;
    };

    this.containerElem.addEventListener('transitionend', handleTransitionEnd);
  }
}
