import { getLogger } from '../logging/logger';
import { SeekRequestCustomData } from './custom-data/seek-request-custom-data';
import { TimeManager } from './time-manager';

const logger = getLogger('SeekRequestHandler');

export class SeekRequestHandler {
  private timeManager: TimeManager;

  constructor(playerManager: cast.framework.PlayerManager, timeManager: TimeManager) {
    this.timeManager = timeManager;

    playerManager.setMessageInterceptor(cast.framework.messages.MessageType.SEEK, this.interceptSeek);
  }

  interceptSeek = (
    requestData: cast.framework.messages.SeekRequestData,
  ): cast.framework.messages.SeekRequestData => {
    const currentTime = requestData.currentTime;
    const customData: SeekRequestCustomData = requestData.customData || {};

    logger.debug('interceptSeek', currentTime, customData);

    if (this.timeManager.isLive()) {
      const liveSeekTarget = this.getLiveSeekTarget(currentTime, customData);
      if (liveSeekTarget !== undefined) {
        requestData.currentTime = liveSeekTarget;
      }
    }

    if (typeof customData.relativeSeek === 'number') {
      requestData.relativeTime = customData.relativeSeek;
    }

    return requestData;
  };

  private getLiveSeekTarget(
    currentTime?: number,
    customData: SeekRequestCustomData = {},
  ): number | undefined {
    if (customData.seekToTimestamp) {
      return (
        this.timeManager.getMediaTimeForAbsoluteTime(customData.seekToTimestamp / 1000) -
        this.timeManager.getCurrentTimeOffset()
      );
    }

    // Snap to end, given a minimum seekable range (randomly picked min length).
    const seekableRange = this.timeManager.getLiveSeekableRange();
    if (seekableRange.start === undefined || seekableRange.end === undefined) {
      return undefined;
    }

    if (seekableRange.end - seekableRange.start >= 180) {
      if (currentTime && currentTime > seekableRange.end - 60) {
        return Infinity;
      }
    }

    if (customData.seekToLive) {
      return Infinity;
    }

    return undefined;
  }
}
